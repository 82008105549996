const COMPANY_DOMAIN_LOCAL_KEY = 'COMPANY_DOMAIN'

interface Props {
  asEmail?: boolean
}

export const setLocalCompanyDomain = (companyDomain: string) => {
  localStorage.setItem(COMPANY_DOMAIN_LOCAL_KEY, companyDomain)
}

export const getCompanyDomainFromLocalStorage = ({ asEmail }: Props = {}) => {
  const companyDomainFromLocalStorage = localStorage.getItem(COMPANY_DOMAIN_LOCAL_KEY)

  if (!companyDomainFromLocalStorage) {
    return null
  }

  return asEmail ? `@${companyDomainFromLocalStorage}` : companyDomainFromLocalStorage
}

export const removeLocalCompanyDomain = () => localStorage.removeItem(COMPANY_DOMAIN_LOCAL_KEY)
