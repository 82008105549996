import { OktaAuth } from '@okta/okta-auth-js'
import { getAuthConfig } from '../getAuthConfig'

export const getOktaAuth = () => {
  const authConfig = getAuthConfig()

  if (!authConfig || !authConfig.configWeb) {
    return null
  }

  const { configWeb } = authConfig

  const oktaConfig = {
    ...configWeb,
    redirectUri: window.location.origin + configWeb.redirectUrl,
  }

  return new OktaAuth(oktaConfig)
}
