export const paths = {
  SignIn: '/signin',
  SignOut: '/signout',
  Download: '/download',
  PasswordActivate: '/activate',
  PasswordReset: '/password_reset',
  OpenIDCallback: '/authorization-code/callback',
  OktaFlowStart: '/okta',
  Unauthorized: '/unauthorized',
}
