import { Swift } from '@swiftctrl/swift-component-library'
import qrCode from 'qrcode'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

export const QRCode: Swift.FC = () => {
  const url: string = 'https://swiftconnect.page.link/start'
  const [imageData, setImageData] = useState('')

  useEffect(() => {
    qrCode.toDataURL(url, { errorCorrectionLevel: 'L' }, (_, url) => {
      setImageData(url)
    })
  }, [])

  return <StyledImage src={imageData} alt="qr-code" />
}

const StyledImage = styled.img`
  min-height: 154px;
  min-width: 200px;
`
