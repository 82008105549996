import { ProfilesTypes } from '@swiftctrl/api-client-react'
import { useState } from 'react'
import { defaultUserState } from '../../../context'
import { UserContextProps } from '../../../models'

export const useUserState = (): UserContextProps => {
  const [state, setState] = useState(defaultUserState)

  const setUser = (user: ProfilesTypes) => {
    setState({ ...state, user })
  }

  const clearUser = () => {
    setState({ ...state, user: undefined })
  }

  return { ...state, setUser, clearUser }
}
