import {
  AuthorizationRequest,
  AuthorizationResponse,
  AuthorizationServiceConfiguration,
  GRANT_TYPE_AUTHORIZATION_CODE,
  StringMap,
  TokenRequest,
} from '@openid/appauth'
import { OpenIDConfig } from '../../models'
import { tokenHandler } from './handlers'

export const performCodeExchange = (
  request: AuthorizationRequest,
  response: AuthorizationResponse,
  configuration: AuthorizationServiceConfiguration,
  configWeb: OpenIDConfig,
) => {
  const extras: StringMap | undefined = {}

  if (request && request.internal) {
    extras.code_verifier = request.internal.code_verifier
  }

  const tokenRequest = new TokenRequest({
    client_id: configWeb.client_id!,
    redirect_uri: window.location.origin + configWeb.redirect_uri!,
    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
    code: response.code,
    refresh_token: undefined,
    extras: extras,
  })

  return tokenHandler.performTokenRequest(configuration, tokenRequest)
}
