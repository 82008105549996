import { ProfilesTypes, useAuth } from '@swiftctrl/api-client-react'
import { Layout, LogOutOutlined, Menu, Swift, Text } from '@swiftctrl/swift-component-library'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useMainStateContext, useTranslation } from '../hooks'
import { paths } from '../utils'
import { UserBasicInfo } from './UserBasicInfo'

const defaultSpacing = 20

interface Props {
  user?: ProfilesTypes
}

export const UserMainMenu: Swift.FC<Props> = ({ user }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { clearUser } = useMainStateContext()
  const { mutate: handleLogout } = useAuth().signOut({
    onSuccess: () => {
      clearUser()
      history.replace(paths.SignIn)
    },
  })

  return (
    <StyledMenu data-cy="user-main-menu">
      <UserBasicInfo user={user} />
      <MenuDivider />
      <StyledMenu.Item data-cy="user-main-menu-logout" key="logout" onClick={() => handleLogout()}>
        <LabelWithIcon label={t('logout_menu')} />
      </StyledMenu.Item>
    </StyledMenu>
  )
}

type IconProps = {
  label: string
}

const LabelWithIcon: Swift.FC<IconProps> = ({ label }) => {
  return (
    <Layout horizontal alignItems="center">
      <IconLayout>
        <LogOutOutlined size="medium" />
      </IconLayout>
      <ResetChildrenLineHeight>
        <Text>{label}</Text>
      </ResetChildrenLineHeight>
    </Layout>
  )
}

// @ts-ignore CAUTION: We need to see what changed that now this triggers and TS error
const StyledMenu = styled(Menu)`
  margin: ${({ theme }) =>
    `${theme.spacing.medium} -12px ${theme.spacing.none} ${theme.spacing.none}`};
  padding: ${defaultSpacing}px;
  width: 320px;
  border-radius: 8px;
  &.ant-menu-vertical {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  .ant-menu-item {
    margin: ${({ theme }) => theme.spacing.none} -${defaultSpacing}px;
    padding: ${({ theme }) => theme.spacing.none} ${defaultSpacing + 10}px;
  }
`

const MenuDivider = styled.div`
  height: ${defaultSpacing}px;
`

const IconLayout = styled(Layout)`
  margin-right: 10px;
`

const ResetChildrenLineHeight = styled.div`
  * {
    line-height: inherit;
  }
`
