import {
  AuthorizationNotifier,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  LocalStorageBackend,
  RedirectRequestHandler,
} from '@openid/appauth'
import { NoHashQueryStringUtils } from './noHashQueryStringUtils'

const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())

const notifier = new AuthorizationNotifier()

const authorizationHandler = new RedirectRequestHandler(
  new LocalStorageBackend(),
  new NoHashQueryStringUtils(),
  window.location,
  new DefaultCrypto(),
)

export { tokenHandler, notifier, authorizationHandler }
