export const USER_AUTHENTICATION_LOCAL_KEY = 'IS_USER_AUTHENTICATED'

export const setLocalUserAuthentication = (status: 'TRUE' | 'FALSE' = 'FALSE') => {
  localStorage.setItem(USER_AUTHENTICATION_LOCAL_KEY, status)
}

const getLocalUserAuthentication = () => localStorage.getItem(USER_AUTHENTICATION_LOCAL_KEY)

export const isUserAuthenticated = () => {
  const localUserAuthentication = getLocalUserAuthentication()

  if (!localUserAuthentication) {
    return false
  }

  return localUserAuthentication.toUpperCase() === 'TRUE'
}
