import { getValue } from 'firebase/remote-config'
import { remoteConfig } from '../components'
import { CompanyConfig } from '../models'

export const getCompanyConfigFromFirebase = (domain: string) => {
  const formattedDomain = domain.replaceAll('.', '')
  const domainValue = getValue(remoteConfig, formattedDomain).asString()

  if (domainValue) {
    return JSON.parse(domainValue) as CompanyConfig
  }

  return null
}
