import { fetchServiceConfiguration } from './fetchServiceConfiguration'
import { performCodeExchange } from './performCodeExchange'
import { authorizationHandler, notifier } from './handlers'
import { getAuthConfig } from '../getAuthConfig'

export const handleCallbackFlow = async (callback: (value: string | undefined) => void) => {
  const authConfig = getAuthConfig()

  if (!authConfig || !authConfig.configWeb) {
    return
  }

  const { configWeb } = authConfig

  const configuration = await fetchServiceConfiguration(configWeb.issuer!)

  authorizationHandler.setAuthorizationNotifier(notifier)

  notifier.setAuthorizationListener(async (request, response) => {
    if (response) {
      const code = await performCodeExchange(request, response, configuration, configWeb)
      callback(code.idToken)
    }
  })

  authorizationHandler.completeAuthorizationRequestIfPossible()
}
