import { Affix, Swift } from '@swiftctrl/swift-component-library'
import { Suspense } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { AppProvider } from './AppProvider'
import {
  FallbackRedirect,
  FullscreenLoader,
  Header,
  RouteWithSubRoutes,
  ScreenLayout,
} from './components'
import { usePrivateRoutes } from './hooks'
import { MainRouteProps } from './models'
import { useInitMixPanel } from './utils'

const App: Swift.FC = () => {
  const mainRoutes: MainRouteProps[] = usePrivateRoutes()
  useInitMixPanel()
  return (
    <AppProvider>
      <Router>
        <Affix>
          <Header />
        </Affix>
        <Suspense fallback={<FullscreenLoader />}>
          <ScreenLayout>
            <Switch>
              {mainRoutes.map((route: MainRouteProps) => (
                <RouteWithSubRoutes key={String(route.path)} {...route} />
              ))}
              <FallbackRedirect />
            </Switch>
          </ScreenLayout>
        </Suspense>
      </Router>
    </AppProvider>
  )
}

export default App
