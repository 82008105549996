import { getRedirectLink } from '@swiftctrl/api-helpers'
import { useCallback, useRef } from 'react'

export const useAuthRedirection = () => {
  const { current: redirectionParams } = useRef<string>(
    getRedirectLink(process.env.REACT_APP_SWIFT_DOMAIN || '') ||
      process.env.REACT_APP_SECURITY_URL!,
  )

  const redirectToApplication = useCallback(() => {
    window.location.href = redirectionParams
  }, [redirectionParams])

  return { redirectToApplication, redirectLink: redirectionParams }
}
