import React from 'react'
import { MainContextProps } from '../models'

export const defaultMainState: MainContextProps = {
  setError: () => {},
  clearError: () => {},
  setUser: () => {},
  clearUser: () => {},
}
export const MainContext = React.createContext<MainContextProps>(defaultMainState)
