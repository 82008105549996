import { SwiftConnectLogo, Layout, media, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

/**
 * Stylized container with the Swift logo, shared by all auth screens.
 */
export const AuthBoxContainer: Swift.FC = ({ children }) => (
  <Container>
    <Layout grow spacing="large">
      <Layout alignItems="center">
        <SwiftConnectLogo width={56} height={88} />
      </Layout>

      {children}
    </Layout>
  </Container>
)

const Container = styled.div`
  width: 440px;

  ${media.phone} {
    width: initial;
  }
`
