import { SessionMFA, useAuth } from '@swiftctrl/api-client-react'
import { AxiosError } from 'axios'
import { differenceInMinutes } from 'date-fns'
import { useState } from 'react'
import { useErrorContext, useGetHoursAndMinutesFromMinutes, useTranslation } from '../../../hooks'
import { mixPanelTrack } from '../../../utils'

export const useSignIn = () => {
  const { setError, clearError } = useErrorContext()
  const [mfaToken, setMfaToken] = useState<SessionMFA>()
  const { t } = useTranslation()
  const { getHoursAndMinutesFromMinutes } = useGetHoursAndMinutesFromMinutes()
  const mutate = useAuth().signIn({
    onError: (error) => {
      mixPanelTrack('Sign-in (Failure)')
      const err: AxiosError = error

      if (err) {
        if (err.response?.data.token && err.response?.status === 403) {
          setMfaToken(err.response.data.token)
        }
        const retry_after: number | undefined = getMinutesUntilRetry(err)
        setError({
          code: err.response?.status.toString()!,
          message: err.message,
          number_of_attempts: err.response?.data.attempts_before_lockout,
          retry_after: (retry_after && getHoursAndMinutesFromMinutes(retry_after)) || undefined,
          alert_header: err.response?.data.attempts_before_lockout
            ? t('error_401_warning_header')
            : err.response?.status === 429
            ? t('error_429_locked_header')
            : undefined,
        })
      }
    },
  })
  return { ...mutate, mfaToken, clearError }
}

const getMinutesUntilRetry = (error: AxiosError | undefined): number | undefined => {
  const retryAfter: string | undefined = error?.response?.headers['retry-after']
  if (!retryAfter) {
    return undefined
  }
  const retryDate: Date = new Date(retryAfter)
  const currentDate: Date = new Date()
  return differenceInMinutes(retryDate, currentDate)
}
