import { SwiftReactApiProvider } from '@swiftctrl/api-client-react'
import { Swift, swiftDefaultTheme, SwiftGlobalStyle } from '@swiftctrl/swift-component-library'
import { fetchAndActivate } from 'firebase/remote-config'
import { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { remoteConfig } from './components'
import { CompanyConfigContextProvider, ErrorContext, UserContext } from './context'
import { useErrorState, useUserState } from './hooks'
import { ErrorContextProps, UserContextProps } from './models'
import { OptimizelyProvider } from './OptimizelyProvider'
import { appTheme } from './styles'

export const AppProvider: Swift.FC = ({ children }) => {
  const userState: UserContextProps = useUserState()
  const errorState: ErrorContextProps = useErrorState()
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    remoteConfig.settings.minimumFetchIntervalMillis = 0
    fetchAndActivate(remoteConfig).then(() => setReady(true))
  }, [])

  if (!ready) {
    return null
  }

  return (
    <ThemeProvider theme={appTheme}>
      <SwiftReactApiProvider
        devtoolOptions={
          process.env.NODE_ENV === 'development' ? { initialIsOpen: false } : undefined
        }
        swiftClientConfig={{
          environment: {
            baseUrl: process.env.REACT_APP_API_DIRECTORY_BASE_URL!,
            apiVersion: parseInt(process.env.REACT_APP_API_DIRECTORY_VERSION!, 10),
          },
        }}
      >
        <OptimizelyProvider>
          <ErrorContext.Provider value={errorState}>
            <UserContext.Provider value={userState}>
              <CompanyConfigContextProvider>
                <SwiftGlobalStyle theme={swiftDefaultTheme} />
                <main>{children}</main>
              </CompanyConfigContextProvider>
            </UserContext.Provider>
          </ErrorContext.Provider>
        </OptimizelyProvider>
      </SwiftReactApiProvider>
    </ThemeProvider>
  )
}
