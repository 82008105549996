import { ErrorMessage, useProfile } from '@swiftctrl/api-client-react'
import {
  AntLayout,
  Avatar,
  Layout,
  Popover,
  Swift,
  SwiftConnectInvertLogo,
  Text,
} from '@swiftctrl/swift-component-library'
import { useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useBoolean, useUserContext, useWhiteListPath } from '../hooks'
import { paths } from '../utils'
import { UserMainMenu } from './UserMainMenu'

export const Header: Swift.FC = () => {
  const [header, showHeader, hideHeader] = useBoolean()
  const { user: userProfile, setUser: setUserProfile } = useUserContext()
  const isPathWhiteListed: boolean = useWhiteListPath()
  const history = useHistory()
  const [userMenuVisibility, showUserMenu, hideUserMenu] = useBoolean()

  useProfile().self(['self'], {
    onSuccess: (res) => {
      setUserProfile(res.data)
    },
    onError: (err: ErrorMessage) => {
      if (err?.response?.status === 401) {
        history.push(paths.SignIn)
      }
    },
    enabled: header,
  })

  const menuAnchor = useRef(null)

  useEffect(() => {
    if (isPathWhiteListed) {
      showHeader()
    } else {
      hideHeader()
    }
  }, [isPathWhiteListed])

  return header ? (
    <MainHeader>
      <Link to="/">
        <Layout>
          <SwiftConnectInvertLogo />
        </Layout>
      </Link>
      {userProfile && (
        <span ref={menuAnchor} data-cy="user-main-menu-trigger">
          <StyledUserInfoButton onClick={showUserMenu}>
            <Avatar
              userId={userProfile?.profile_id}
              name={userProfile?.display_name || 'NA'}
              src={userProfile?.avatar}
              size="small"
            />
            <Text size="14px">{userProfile?.display_name}</Text>
          </StyledUserInfoButton>
        </span>
      )}
      <StyledPopover
        open={userMenuVisibility}
        anchorEl={menuAnchor?.current}
        onClose={hideUserMenu}
        disableScrollLock
      >
        <UserMainMenu user={userProfile} />
      </StyledPopover>
    </MainHeader>
  ) : null
}

const MainHeader = styled(AntLayout.Header)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: 100;
  padding: ${({ theme }) =>
    `${theme.spacing.none} ${theme.spacing.xMedium} ${theme.spacing.none} ${theme.spacing.medium}`};
`

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    background: transparent;
    overflow: visible;
  }
`

export const SilentButton = styled.button`
  background-color: transparent;
  border: none;
  padding: ${({ theme }) => theme.spacing.none};
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  width: 100%;
`

const StyledUserInfoButton = styled(SilentButton)`
  ${({ theme }) => `
    align-items: center;
    color: ${theme.designTokens.colors.content.inverted.accent};
    display: flex;

    .ant-avatar {
      margin-right: ${theme.spacing.small}
    }
 `}
`
