import { lazy } from 'react'

export const SignInScreen = lazy(() => import('./screens/SignInScreen'))
export const PasswordReset = lazy(() => import('./screens/PasswordReset'))
export const PasswordActivateScreen = lazy(() => import('./screens/PasswordActivateScreen'))
export const DownloadPageScreen = lazy(() => import('./screens/DownloadPageScreen'))
export const SignOutScreen = lazy(() => import('./screens/SignOutScreen'))
export const OpenIDCallbackScreen = lazy(() => import('./screens/OpenIDCallbackScreen'))
export const OktaFlowStartScreen = lazy(() => import('./screens/OktaFlowStartScreen'))
export const UnauthorizedUserScreen = lazy(() => import('./screens/UnauthorizedUserScreen'))
