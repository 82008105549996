import { createInstance, OptimizelyProvider as _OptimizelyProvider } from '@optimizely/react-sdk'
import { Swift } from '@swiftctrl/swift-component-library'
import { minutesToMilliseconds } from 'date-fns'

// Configs
const SDK_KEY = process.env.REACT_APP_OPTIMIZELY_SDK_KEY
const POLLING_INTERVAL_MS = minutesToMilliseconds(1)
const PROXY_URL = 'https://cdn.fe.dev.swiftconnect.io/%s'

const optimizely = createInstance({
  sdkKey: SDK_KEY,
  datafileOptions: {
    // Tell the SDK to keep polling for changes
    // Note: doesn't re-render by default, the `useFeature` will still need to specify autoUpdate if wanted
    autoUpdate: true,
    updateInterval: POLLING_INTERVAL_MS,
    urlTemplate: PROXY_URL,
  },
  logLevel: 'warn',
})

export const OptimizelyProvider: Swift.FC = ({ children }) => {
  return (
    <_OptimizelyProvider optimizely={optimizely} user={{ id: 'public-user' }}>
      {children}
    </_OptimizelyProvider>
  )
}
