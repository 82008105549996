import { getCompanyConfigFromFirebase } from './getCompanyConfigFromFirebase'
import { getCompanyDomainFromLocalStorage } from './localCompanyDomain'

export const getAuthConfig = () => {
  const companyDomainFromLocalStorage = getCompanyDomainFromLocalStorage()

  if (!companyDomainFromLocalStorage) {
    return null
  }

  const companyConfig = getCompanyConfigFromFirebase(companyDomainFromLocalStorage!)

  if (!companyConfig || !companyConfig.auth) {
    return null
  }

  return companyConfig.auth
}
