import { Swift } from '@swiftctrl/swift-component-library'
import { createContext, useState } from 'react'
import { CompanyConfig } from '../models'

interface CompanyConfigContextProps {
  companyConfig: CompanyConfig
  setCompanyConfig: (value: CompanyConfig) => void
}

const defaultState: CompanyConfigContextProps = {
  companyConfig: {},
  setCompanyConfig: (value: CompanyConfig) => {},
}

export const CompanyConfigContext = createContext<CompanyConfigContextProps>(defaultState)

export const CompanyConfigContextProvider: Swift.FC = ({ children }) => {
  const [companyConfig, setCompanyConfig] = useState(defaultState.companyConfig)

  return (
    <CompanyConfigContext.Provider value={{ companyConfig, setCompanyConfig }}>
      {children}
    </CompanyConfigContext.Provider>
  )
}
