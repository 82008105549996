const SIGNINTYPENAME = '_sign-in-type'
const SIGNINTYPE = 'sso'
const COOKIEDOMAIN = '.swiftconnect.io'

export const setSignInTypeCookie = () => {
  document.cookie = SIGNINTYPENAME + '=' + SIGNINTYPE + ';domain=' + COOKIEDOMAIN + ';path=/'
}

export const removeSignInTypeCookie = () => {
  if (hasSignInTypeCookie) {
    document.cookie = SIGNINTYPENAME + '=;' + ';domain=' + COOKIEDOMAIN + ';path=/'
  }
}

export const hasSignInTypeCookie = document.cookie.includes(SIGNINTYPENAME + '=' + SIGNINTYPE)
