import { useState } from 'react'
import { useLocation } from 'react-router-dom'

// This is a hook to check if the current path is in the white list to display the header, For now we don't need it and it always returns false

export const useWhiteListPath = (): boolean => {
  const [whiteListHeaderPath] = useState<string[]>([])
  const location = useLocation()
  return whiteListHeaderPath.includes(location.pathname)
}
