import { useTranslation } from '../../useTranslation'
import * as yup from 'yup'

export const useCompanyDomainValidation = () => {
  const { t } = useTranslation()

  const domainValidator = yup
    .string()
    .matches(/^[^\s@]+\.[^\s@]+$/, t('forms_company_domain_error_invalid'))
    .required(t('forms_company_domain_error_required'))

  return domainValidator
}
