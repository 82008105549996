import { Layout, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import apple from '../assets/apple-appstore-download.svg'
import google from '../assets/google-play-store-download.svg'
import { useTranslation } from '../hooks'

export const DesktopDownloadAppContainer: Swift.FC = () => {
  const { t } = useTranslation()
  return (
    <Layout spacing="medium">
      <StyledImage
        src={apple}
        onClick={() =>
          window.open('https://apps.apple.com/us/app/swiftconnect-mobile/id1585852507', '_blank')
        }
        alt={t('download_app_store_alt')}
      />
      <StyledImage
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=io.swiftconnect.mobile',
            '_blank',
          )
        }
        src={google}
        alt={t('download_play_store_alt')}
      />
    </Layout>
  )
}
const StyledImage = styled.img`
  cursor: pointer;
`
