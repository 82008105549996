import { en } from './en'

// Use the english translations as a base to make sure we're not missing any
type Labels = typeof en

export const fr: Labels = {
  app_name: 'Swift Accounts',

  menu_home: 'Accueil',
  menu_users: 'Utilisateurs',
  menu_login: 'Connexion',
  error_minute_label: 'min',
  error_minutes_label: 'mins',
  error_hour_label: 'heure',
  error_hours_label: 'heures',
  error_400: 'Il y a eu un problème de connexion',
  error_401_warning_header: 'Attention',
  error_429_locked_header: 'Compte verrouillé',
  error_401_default:
    'Identifiants incorrects. Veuillez réessayer ou cliquez sur Mot de passe oublié pour le réinitialiser.',
  error_401:
    "Vous avez entré des informations d'identification incorrectes. <strong> {{number_of_attempts}} autre tentative infructueuse entraînera le verrouillage de votre compte.</strong> Veuillez réessayer ou cliquez sur Mot de passe oublié pour le réinitialiser.",
  error_401_unauthorized_user_header: 'Non autorisé',
  error_401_unauthorized_user:
    "Il semble que vous ne soyez pas autorisé à afficher cette page. Si vous pensez qu'il s'agit d'une erreur, contactez votre administrateur.",
  error_429:
    "Votre compte a été verrouillé en raison d'un nombre excessif de tentatives de connexion infructueuses. Votre compte sera automatiquement déverrouillé dans <strong>{{retry_after}}</strong>",
  error_429_default:
    "Votre compte a été verrouillé en raison d'un nombre excessif de tentatives de connexion infructueuses. Votre compte sera automatiquement déverrouillé dans <strong>quelques minutes</strong>",
  error_503: 'Un problème est survenu. veuillez réessayer plus tard.',
  error_default: 'Il y a eu un problème de connexion',

  forms_login_logo_alt: 'Swift Ctrl Logo',
  forms_login_title: 'Connexion',
  forms_login_type_use_email: 'Se connecter par courriel',
  forms_login_type_use_google: 'Se connecter avec Google',

  forms_login_email: 'Couriel',
  forms_login_email_error_required: 'Veuillez saisir un couriel valide',
  forms_login_email_error_invalid: "Ce n'est pas un couriel valide",
  forms_login_password: 'Mot de passe',
  forms_login_password_required: 'Veuillez saisir votre mot de passe',
  forms_login_password_reset_link: 'Mot de passe oublié',
  forms_login_login_button: 'Connexion',
  forms_login_signing_in_with_text: 'Ne pas se connecter avec',
  forms_login_go_back: 'Retourner',

  // Password Reset (step 1 - enter email)
  forms_passwordreset1_title: 'Mot de passe oublié?',
  forms_passwordreset1_subtitle:
    'Entrez votre adresse courriel pour réinitialiser votre mot de passe',
  forms_passwordreset1_email: 'Adresse courriel',
  forms_passwordreset1_email_error_required: 'Le courriel ne peut pas être vide',
  forms_passwordreset1_email_error_invalid: 'Format invalide',
  forms_passwordreset1_back_button: 'Retourner en arrière',
  forms_passwordreset1_submit_button: 'Envoyer courriel',
  forms_passwordreset1_success_description:
    "Si le compte {{email}} existe, vous recevrez un courriel contenant des instructions indiquant comment réinitialiser votre mot de passe. S'il n'est pas encore arrivé, assurez-vous de vérifier votre dossier spam.",
  forms_passwordreset1_success_close_button: 'Retourner en arrière',

  // Password Reset (step 3 - enter new password)
  forms_passwordreset3_title: 'Réinitialisez votre mot de passe',
  forms_passwordreset3_password: 'Nouveau mot de passe',
  forms_passwordreset3_password_rule_length: 'Entre 8 et 72 caractères',
  forms_passwordreset3_password_rule_case: 'Au moins un caractère minuscule et majuscule',
  forms_passwordreset3_password_rule_special_char: 'Au moins un chiffre ou caractère spécial',
  forms_passwordreset3_submit_button: 'Réinitialiser',
  forms_passwordreset3_success_title: 'Mot de passe mis à jour',
  forms_passwordreset3_success_description:
    'Veuillez vous reconnecter avec votre nouveau mot de passe.',

  // password activate
  forms_password_activate_title: 'Bienvenue',
  forms_password_activate_title_name: 'Bienvenue {{name}}',
  forms_password_activate_subtitle: 'Créez un mot de passe pour activer votre compte',
  forms_password_activate_password_label: 'Nouveau mot de passe',
  forms_password_activate_password_placeholder: 'Tapez votre mot de passe',
  forms_password_activate_confirm_password_label: 'Confirmer le nouveau mot de passe',
  forms_password_activate_confirm_password_placeholder: 'Confirmer votre mot de passe',
  forms_password_activate_password_error_required: 'Le mot de passe ne correspond pas',
  forms_password_activate_password_button_label: 'Activer le compte',

  // password reset
  forms_password_reset_404_title: 'Ce lien a expiré',
  forms_password_reset_404_description:
    'Veuillez demander un nouveau lien de réinitialisation du mot de passe et réessayer.',

  // password activate success
  forms_password_activate_success_title: 'Votre compte a été activé',
  forms_password_activate_success_description:
    "Cliquez sur le bouton ci-dessous pour vous connecter et accéder à l'application.",
  forms_password_activate_success_button_label: 'Fait',

  // password activate error
  forms_password_activate_error_title: "Ce lien n'est pas valide",
  forms_password_activate_error_description:
    "Veuillez contacter votre organisation pour plus d'informations.",
  forms_password_activate_error_description_email_contact: 'Email : {{email}}',
  forms_password_activate_error_description_phone_contact: 'Numéro de téléphone : {{phone}}',

  // company dispatcher
  forms_company_domain_continue_button: 'Continue',
  forms_company_confirm_continue_button: 'Continue à {{label}}',
  forms_company_confirm_continue_sign_in_button: 'Continue to sign in',
  forms_company_domain_label: "Domaine de messagerie de l'entreprise",
  forms_company_domain_error_invalid: 'Veuillez saisir un domain valide',
  forms_company_domain_error_not_exist: "Il semble que ce domaine n'existe pas.",
  forms_company_domain_error_required: 'Le domain ne peut pas être vide',
  forms_company_domain_placeholder: 'e.g.: example.com',
  forms_company_domain_title: 'Bienvenue',
  forms_company_domain_text:
    'Saisissez le domaine de messagerie de votre entreprise pour vous connecter. ',
  forms_company_domain_tooltip_text:
    "C'est ce qui apparaît après le '@ dans votre e-mail (e.g.: you@example.com)",

  // MFA
  forms_mfa_title: 'Authentification à deux facteurs',
  forms_mfa_sub_title: 'Un code de vérification à 6 chiffres a été envoyé à {{email}}',
  forms_mfa_input_label: 'le code de vérification',
  forms_mfa_input_placeholder_label: 'Entrez le code de vérification',
  forms_mfa_button_label: "Vous n'avez pas reçu de code de vérification ?",
  forms_mfa_send_code: 'Envoyer un nouveau code',
  forms_mfa_error_message_enter_valid_code: 'Veuillez entrer un code de vérification valide',
  forms_mfa_back: 'Retour',

  // download app
  download_app_title: 'Bonjour,',
  download_app_subtitle: "Bénéficiez d'un accès fluide depuis votre téléphone",
  download_play_store_alt: 'Bouton de téléchargement Google Play Store',
  download_app_store_alt: 'Bouton de téléchargement Apple App Store',
  download_app_administrotor_sign_in: 'Connexion Administrateur',

  // fallback
  fallback_title: 'NA',

  // header
  logout_menu: 'Se déconnecter',
}
