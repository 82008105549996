export const getButtonLabelFromCompanyConfigIssuer = (value: string | undefined) => {
  if (!value) {
    return
  }

  if (value.includes('ping')) {
    return 'Ping'
  }

  if (value.includes('okta')) {
    return 'Okta'
  }

  return undefined
}
