import { useTranslation } from './useTranslation'

export const useGetHoursAndMinutesFromMinutes = () => {
  const { t } = useTranslation()

  const getMinutes = (minutes: number) =>
    minutes <= 1
      ? `${minutes} ${t('error_minute_label')}`
      : `${minutes % 60} ${t('error_minutes_label')}`
  const getHours = (minutes: number) => {
    const hours = Math.trunc(minutes / 60)
    return hours <= 1 ? `${hours} ${t('error_hour_label')}` : `${hours} ${t('error_hours_label')}`
  }

  const getHoursAndMinutesFromMinutes = (minutes?: number) => {
    if (!minutes) {
      return undefined
    }
    if (minutes < 60) {
      return getMinutes(minutes)
    }
    return `${getHours(minutes)} ${getMinutes(minutes)}`
  }

  return { getHoursAndMinutesFromMinutes, getMinutes, getHours }
}
