import mixpanel from 'mixpanel-browser'

type Params = Parameters<typeof mixpanel.track>
type EventName =
  | 'Sign-in (Success)'
  | 'Sign-in (Failure)'
  | 'Reset Password (Success)'
  | 'Reset Password (Failure)'
  | 'Password Creation (Success)'
  | 'Password Creation (Failure)'

type Properties = Params[1]
type OptionsOrCallback = Params[2]
type Callback = Params[3]

export const mixPanelTrack = (
  key: EventName,
  properties?: Properties,
  optionsOrCallback?: OptionsOrCallback,
  callback?: Callback,
) => mixpanel.track(key, properties, optionsOrCallback, callback)
