import { AuthorizationRequest, AuthorizationServiceConfiguration } from '@openid/appauth'
import { OpenIDConfig } from '../../models'
import { authorizationHandler } from './handlers'

export const performAuthorizationRequest = (
  configuration: AuthorizationServiceConfiguration,
  configWeb: OpenIDConfig,
) => {
  const request = new AuthorizationRequest({
    client_id: configWeb.client_id!,
    redirect_uri: window.location.origin + configWeb.redirect_uri!,
    scope: configWeb.scope!,
    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
    state: undefined,
  })

  return authorizationHandler.performAuthorizationRequest(configuration, request)
}
