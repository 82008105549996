import { ErrorProps } from '@swiftctrl/api-client-react'
import { useState } from 'react'
import { defaultErrorState } from '../../../context'
import { ErrorContextProps } from '../../../models'

export const useErrorState = (): ErrorContextProps => {
  const [state, setState] = useState(defaultErrorState)

  const setError = (err: ErrorProps) => {
    setState({ ...state, error: err })
  }

  const clearError = () => {
    setState({ ...state, error: undefined })
  }

  return { ...state, setError, clearError }
}
