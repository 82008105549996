import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CompanyConfigContext } from '../context'
import { StateContent } from '../models'
import {
  getCompanyConfigFromFirebase,
  getCompanyDomainFromLocalStorage,
  paths,
  removeLocalCompanyDomain,
  setLocalCompanyDomain,
} from '../utils'
import { useCompanyDomainFromUrlParams } from './useCompanyDomainFromUrlParams'

export const useHandleNavigation = () => {
  const [signInContent, setSignInContent] = useState<StateContent>('signin')
  const companyDomainFromLocalStorage = getCompanyDomainFromLocalStorage()
  const { companyConfig, setCompanyConfig } = useContext(CompanyConfigContext)
  const history = useHistory()

  const [domainExists, setDomainExists] = useState<boolean>(true)
  const { companyDomainFromUrlParams } = useCompanyDomainFromUrlParams()

  useEffect(() => {
    const loadedCompanyDomain = companyDomainFromLocalStorage

    if (!loadedCompanyDomain) {
      setSignInContent('signin')
      return
    }

    const companyConfig = getCompanyConfigFromFirebase(loadedCompanyDomain)

    if (!companyConfig) {
      return
    }

    setLocalCompanyDomain(loadedCompanyDomain)
    setCompanyConfig(companyConfig)
    setSignInContent('companyDomainConfirmation')
  }, [])

  useEffect(() => {
    if (companyDomainFromUrlParams) {
      const companyConfig = getCompanyConfigFromFirebase(companyDomainFromUrlParams)

      if (!companyConfig) {
        removeLocalCompanyDomain()
        setSignInContent('signin')
        setDomainExists(false)
        return
      }

      setLocalCompanyDomain(companyDomainFromUrlParams)
      setCompanyConfig(companyConfig)

      if (companyConfig?.auth?.strategy === 'okta') {
        history.push(paths.OktaFlowStart)
      } else {
        setSignInContent('companyDomainInput')
      }
    }
  }, [])

  const handleNavigation = (content: StateContent) => {
    setSignInContent(content)
  }

  return { signInContent, setSignInContent, domainExists, handleNavigation, companyConfig }
}
