import { useTranslation as useTranslation_i18next } from 'react-i18next'
import { en } from '../locales'

export type TxKey = Extract<keyof typeof en, string>
export type TranslationFunction = (key: TxKey, options?: object) => string

// Wrap the hook to enforce that only valid keys get passed in
export const useTranslation = () => {
  const { t, i18n } = useTranslation_i18next()

  return {
    t: t as TranslationFunction,
    i18n,
  }
}
