import { ProfilesTypes } from '@swiftctrl/api-client-react'
import { Avatar, Layout, Swift, Text } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { useTranslation } from '../hooks'

interface Props {
  user?: ProfilesTypes
}

export const UserBasicInfo: Swift.FC<Props> = ({ user }: Props) => {
  const { t } = useTranslation()

  return (
    <Layout horizontal spacing="medium">
      <Avatar
        userId={user?.profile_id}
        name={user?.display_name || t('fallback_title')}
        src={user?.avatar}
        size="large"
      />
      <Layout grow>
        <Text>{user?.display_name || t('fallback_title')}</Text>
        {user?.emails && user?.emails.length > 0 && (
          <WordWrappingContainer>
            <Text size="14px">{user?.emails[0].email}</Text>
          </WordWrappingContainer>
        )}
      </Layout>
    </Layout>
  )
}

export const WordWrappingContainer = styled.div`
  overflow-wrap: anywhere;
`
