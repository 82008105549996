import { media } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export const FullScreenContainer = styled.main<{ $mobile: boolean }>`
  min-height: ${({ $mobile }) => ($mobile ? 'default' : '100vh')};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5em;

  ${media.tablet} {
    width: 100vw;
    padding: 0;
  }

  ${media.phone} {
    width: 100vw;
    padding: 0;
  }
`

export const FullScreenContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
