import {
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
  Rule,
  useValidation,
} from '@swiftctrl/swift-component-library'
import { useTranslation } from '../useTranslation'

// Rule 1
const hasRequiredLength = (pwd: string) => pwd.length >= 8 && pwd.length <= 72

// Rule 2
const hasRequiredCase = (pwd: string) => hasLowerCase(pwd) && hasUpperCase(pwd)

// Rule 3
const hasRequiredCharacters = (pwd: string) => hasNumber(pwd) || hasSpecialCharacter(pwd)

/**
 * Specialization of `useValidation`.
 * Verifies a string against our own opinionated password requirements.
 */
export const usePasswordValidation = (password: string) => {
  const { t } = useTranslation()
  const rules: Rule[] = [
    {
      label: t('forms_passwordreset3_password_rule_length'),
      validate: hasRequiredLength,
    },
    {
      label: t('forms_passwordreset3_password_rule_case'),
      validate: hasRequiredCase,
    },
    {
      label: t('forms_passwordreset3_password_rule_special_char'),
      validate: hasRequiredCharacters,
    },
  ]

  return {
    ...useValidation(rules, password),
    rules,
  }
}
