import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'

import { initMixpanel } from './initMixpanel'
import { registerMixpanelSuperProps } from './registerMixpanelSuperProps'

/** Hook thant handles the Mixpanel singleton life cycle  */
export const useInitMixPanel = () => {
  useEffect(() => {
    initMixpanel()
    mixpanel.reset()
    registerMixpanelSuperProps()
  }, [])
}
