export const en = {
  app_name: 'Swift Accounts',

  // Site Menu
  menu_home: 'Home',
  menu_users: 'Users',
  menu_login: 'Login',
  error_minute_label: 'min',
  error_minutes_label: 'mins',
  error_hour_label: 'hour',
  error_hours_label: 'hours',
  error_400: 'We had a problem while attempting to sign you in',
  error_401_warning_header: 'Warning',
  error_429_locked_header: 'Locked account',
  error_401_default:
    'Incorrect credentials. Please try again or click Forgot password to reset it.',
  error_401:
    'You have entered incorrect credentials. <strong> {{number_of_attempts}} more failed attempt will cause your account to be locked.</strong> Please try again or click Forgot password to reset it.',
  error_401_unauthorized_user_header: 'Unauthorized',
  error_401_unauthorized_user:
    "It looks like you're not authorized to view this page. If you think this is an error, contact your administrator.",
  error_429:
    'Your account has been locked due to an excessive amount of unsuccessful sign in attempt. Your account will automatically unlock in <strong>{{retry_after}}</strong>',
  error_429_default:
    'Your account has been locked due to an excessive amount of unsuccessful sign in attempt. Your account will be automatically unlock in <strong>few mins</strong>',
  error_503: 'It looks like something went wrong. Please try again later.',
  error_default: 'We had a problem while attempting to sign you in',

  forms_login_logo_alt: 'Swift Ctrl Logo',
  forms_login_title: 'Sign In',
  forms_login_type_use_email: 'Sign In With Email',
  forms_login_type_use_google: 'Sign In With Google',

  forms_login_email: 'Email',
  forms_login_email_error_required: 'Please enter a valid email',
  forms_login_email_error_invalid: 'This is not a valid email',
  forms_login_password: 'Password',
  forms_login_password_required: 'Please enter your password',
  forms_login_password_reset_link: 'Forgot password?',
  forms_login_login_button: 'Sign in',
  forms_login_signing_in_with_text: 'Not signing in with',
  forms_login_go_back: 'Change',

  // Password Reset (step 1 - enter email)
  forms_passwordreset1_title: 'Forgot password?',
  forms_passwordreset1_subtitle: 'Enter your email to reset your password',
  forms_passwordreset1_email: 'Enter your email',
  forms_passwordreset1_email_error_required: "Email can't be blank",
  forms_passwordreset1_email_error_invalid: 'Invalid email format',
  forms_passwordreset1_back_button: 'Return to log in',
  forms_passwordreset1_submit_button: 'Send email',
  forms_passwordreset1_success_description:
    'If the account {{email}} exists, you will receive an email with instructions on how to reset your password. If the email hasn’t arrived yet, be sure to check your spam folder.',
  forms_passwordreset1_success_close_button: 'Return to log in',

  // Password Reset (step 3 - enter new password)
  forms_passwordreset3_title: 'Password reset',
  forms_passwordreset3_password: 'Enter new password',
  forms_passwordreset3_password_rule_length: 'Between 8 and 72 characters',
  forms_passwordreset3_password_rule_case: 'At least one lowercase and uppercase character',
  forms_passwordreset3_password_rule_special_char: 'At least one number or special character',
  forms_passwordreset3_submit_button: 'Reset',
  forms_passwordreset3_success_title: 'Your password was updated',
  forms_passwordreset3_success_description: 'Please sign in again with your new password.',

  // password activate
  forms_password_activate_title: 'Welcome',
  forms_password_activate_title_name: 'Welcome {{name}}',
  forms_password_activate_subtitle: 'Create a password to activate your account',
  forms_password_activate_password_label: 'New password',
  forms_password_activate_password_placeholder: 'Enter your password',
  forms_password_activate_confirm_password_label: 'Confirm new password',
  forms_password_activate_confirm_password_placeholder: 'Confirm your password',
  forms_password_activate_password_error_required: 'Passwords don’t match',
  forms_password_activate_password_button_label: 'Activate account',

  // password reset
  forms_password_reset_404_title: 'The link has expired',
  forms_password_reset_404_description: 'Please request a new password reset link and try again.',

  // password activate success
  forms_password_activate_success_title: 'Your account has been activated',
  forms_password_activate_success_description:
    'Click on the button Sign In and access the application.',
  forms_password_activate_success_button_label: 'Done',

  // password activate error
  forms_password_activate_error_title: 'This link is not valid',
  forms_password_activate_error_description:
    'Please contact your organization for more information.',
  forms_password_activate_error_description_email_contact: 'Email : ',
  forms_password_activate_error_description_phone_contact: 'Phone number : {{phone}}',

  // company dispatcher
  forms_company_domain_continue_button: 'Continue',
  forms_company_confirm_continue_button: 'Continue to {{label}}',
  forms_company_confirm_continue_sign_in_button: 'Continue to sign in',
  forms_company_domain_label: 'Company email domain',
  forms_company_domain_error_invalid: 'This is not a valid domain',
  forms_company_domain_error_not_exist: "It seems that domain doesn't exist.",
  forms_company_domain_error_required: "Domain can't be blank",
  forms_company_domain_placeholder: 'e.g.: example.com',
  forms_company_domain_title: 'Welcome',
  forms_company_domain_text: 'Enter your company email domain to sign in. ',
  forms_company_domain_tooltip_text:
    'This is what appears after the ‘@’ in your email (e.g.: you@example.com)',

  // MFA
  forms_mfa_title: 'Two-Factor Authentication',
  forms_mfa_sub_title: 'A 6-digit verification code has been sent to {{email}}',
  forms_mfa_input_label: 'Verification code',
  forms_mfa_input_placeholder_label: 'Enter verification code',
  forms_mfa_button_label: "Didn't receive a verification code?",
  forms_mfa_send_code: 'Send a new code',
  forms_mfa_error_message_enter_valid_code: 'Please enter a valid verification code',
  forms_mfa_back: 'Back',

  // download app
  download_app_title: 'Hello,',
  download_app_subtitle: 'Get seamless access from your phone',
  download_play_store_alt: 'Google play store download button',
  download_app_store_alt: 'Apple app store download button',
  download_app_administrotor_sign_in: 'Administrator Sign in',

  // fallback
  fallback_title: 'NA',

  // header
  logout_menu: 'Logout',
}
