import { useProfile } from '@swiftctrl/api-client-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SignInFormProps } from '../screen-components'
import {
  hasSignInTypeCookie,
  mixPanelTrack,
  paths,
  removeSignInTypeCookie,
  setLocalUserAuthentication,
  USER_AUTHENTICATION_LOCAL_KEY,
} from '../utils'
import { useHandleUrlParams } from './useHandleUrlParams'
import { useAuthRedirection } from './validators'
import { useSignIn } from './web'

export const useHandleFormCall = () => {
  const { redirectToApplication } = useAuthRedirection()
  const { currentParams } = useHandleUrlParams()
  const { mutate, mfaToken, isLoading, clearError, error } = useSignIn()
  const history = useHistory()
  const [email, setEmail] = useState<string>('')

  const handleAuthentication = () => {
    setLocalUserAuthentication('TRUE')
    if (currentParams?.activate) {
      return history.replace(paths.Download)
    }
    redirectToApplication()
  }

  const { isError } = useProfile().self(['self'], {
    onSuccess: handleAuthentication,
    onError: () => {
      setLocalUserAuthentication('FALSE')
    },
    retry: 0,
  })

  const onFormValidated = (data: SignInFormProps) => {
    setEmail(data.email)
    mutate(
      { payload: { email: data.email, password: data.password }, loginType: 'email_password' },
      {
        onSuccess: () => {
          mixPanelTrack('Sign-in (Success)')
          setTimeout(() => {
            handleAuthentication()
            // Safe delay to make sure the event is properly queued (not meaning registered in the mixpanel server)
            // The event in MixPanel will be registered the next time user visits Accounts App
          }, 400)
        },
      },
    )
  }

  const onFormSendDigits = (data: string) => {
    mutate({
      payload: { token: mfaToken, code: data },
      loginType: 'multi_factor',
    })
  }

  useEffect(() => {
    if (hasSignInTypeCookie) {
      removeSignInTypeCookie()
    }
    const onUserAuthenticationChange = (e: StorageEvent) => {
      if (e.storageArea && e.storageArea[USER_AUTHENTICATION_LOCAL_KEY].toUpperCase() === 'TRUE') {
        handleAuthentication()
      }
    }
    window.addEventListener('storage', onUserAuthenticationChange)
  }, [handleAuthentication])

  const shouldShowSignIn = isError

  return {
    onFormValidated,
    onFormSendDigits,
    mfaToken,
    error: error?.response?.data.message,
    clearError,
    isLoading,
    email,
    shouldShowSignIn,
  }
}
