import { PreLoader, Swift } from '@swiftctrl/swift-component-library'
import React from 'react'
import styled from 'styled-components'

export const FullscreenLoader: Swift.FC = () => {
  return (
    <LoaderWrapper>
      <PreLoader size="large" />
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`
