import {
  DownloadPageScreen,
  OktaFlowStartScreen,
  OpenIDCallbackScreen,
  PasswordActivateScreen,
  PasswordReset,
  SignInScreen,
  SignOutScreen,
  UnauthorizedUserScreen,
} from '../../lazyComponents'
import { MainRouteProps } from '../../models'
import { paths } from '../../utils'

export const usePrivateRoutes = (): MainRouteProps[] => [
  {
    path: paths.Download,
    component: DownloadPageScreen,
    exact: true,
  },
  {
    path: paths.PasswordActivate,
    component: PasswordActivateScreen,
    exact: true,
  },
  {
    path: paths.SignIn,
    component: SignInScreen,
  },
  {
    path: paths.SignOut,
    component: SignOutScreen,
    exact: true,
  },
  {
    path: paths.PasswordReset,
    component: PasswordReset,
    exact: true,
  },
  {
    path: paths.OktaFlowStart,
    component: OktaFlowStartScreen,
  },
  {
    path: paths.OpenIDCallback,
    component: OpenIDCallbackScreen,
    exact: true,
  },
  {
    path: paths.Unauthorized,
    component: UnauthorizedUserScreen,
    exact: true,
  },
]
