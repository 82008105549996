import { Swift } from '@swiftctrl/swift-component-library'
import { useMobileView, useWhiteListPath } from '../../hooks'
import { FullScreenContainer, FullScreenContentContainer } from './Container'

export const ScreenLayout: Swift.FC = ({ children }) => {
  const { isMobile } = useMobileView()
  const isPathWhiteListed: boolean = useWhiteListPath()
  return (
    <FullScreenContainer $mobile={isMobile && isPathWhiteListed}>
      <FullScreenContentContainer>{children}</FullScreenContentContainer>
    </FullScreenContainer>
  )
}
