import { getAuthConfig } from '../getAuthConfig'
import { fetchServiceConfiguration } from './fetchServiceConfiguration'
import { performAuthorizationRequest } from './performAuthorizationRequest'

export const startSignInFlow = async () => {
  const authConfig = getAuthConfig()

  if (!authConfig || !authConfig.configWeb) {
    return
  }

  const { configWeb } = authConfig

  const configuration = await fetchServiceConfiguration(configWeb.issuer!)

  performAuthorizationRequest(configuration, configWeb)
}
